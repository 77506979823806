exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adm-cotizador-admin-js": () => import("./../../../src/pages/adm/cotizador-admin.js" /* webpackChunkName: "component---src-pages-adm-cotizador-admin-js" */),
  "component---src-pages-adm-editar-perfil-negocio-js": () => import("./../../../src/pages/adm/editar-perfil-negocio.js" /* webpackChunkName: "component---src-pages-adm-editar-perfil-negocio-js" */),
  "component---src-pages-cotizacion-pedido-uuid-js": () => import("./../../../src/pages/cotizacion/[pedido_uuid].js" /* webpackChunkName: "component---src-pages-cotizacion-pedido-uuid-js" */),
  "component---src-pages-cotizaciones-js": () => import("./../../../src/pages/cotizaciones.js" /* webpackChunkName: "component---src-pages-cotizaciones-js" */),
  "component---src-pages-cotizador-js": () => import("./../../../src/pages/cotizador.js" /* webpackChunkName: "component---src-pages-cotizador-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mi-cuenta-js": () => import("./../../../src/pages/mi-cuenta.js" /* webpackChunkName: "component---src-pages-mi-cuenta-js" */),
  "component---src-pages-productos-js": () => import("./../../../src/pages/productos.js" /* webpackChunkName: "component---src-pages-productos-js" */),
  "component---src-pages-registro-js": () => import("./../../../src/pages/registro.js" /* webpackChunkName: "component---src-pages-registro-js" */),
  "component---src-pages-sysadmin-deploys-js": () => import("./../../../src/pages/sysadmin/deploys.js" /* webpackChunkName: "component---src-pages-sysadmin-deploys-js" */),
  "component---src-pages-ticket-js": () => import("./../../../src/pages/ticket.js" /* webpackChunkName: "component---src-pages-ticket-js" */)
}

