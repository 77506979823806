/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react"
import "firebase/auth"
import "firebase/database"
import "bootswatch/dist/simplex/bootstrap.min.css"
// import "bootstrap/dist/css/bootstrap.min.css"

import './src/css/global.css'
import RootElement from "./src/components/rootElement"
import { shouldUpdateScrollFn, wrapPageElementQueryProvider } from "./src/utils/browser_ssr_utils"

// You can delete this file if you're not using it

// import "./node_modules/bootstrap/dist/css/bootstrap.css"
// import "./node_modules/bootswatch/dist/cosmo/bootstrap.min.css"


export const shouldUpdateScroll = shouldUpdateScrollFn

export const wrapPageElement = wrapPageElementQueryProvider

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};
